var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-popup',_vm._b({attrs:{"title":"Выбор урока/материала","icon":_vm.UtilGetIcon('view-knowledgebase'),"width":_vm.width,"height":_vm.height,"buttonSaveText":'Копировать'},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_vm._t("activator",null,null,scope)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'base-popup',_vm.$attrs,false),[_c('v-container',[_c('v-row',[_c('v-col',[_c('base-panel',{attrs:{"title":"Курсы"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[0]),callback:function ($$v) {_vm.$set(_vm.search, 0, $$v)},expression:"search[0]"}}),_c('base-table',{attrs:{"items":_vm.courses,"search-text":_vm.search[0],"headers":_vm.courseHeaders,"hide-default-footer":"","notShowSelect":"","notShowSettings":"","notFilter":""},on:{"click:row":function($event){_vm.selectCourse = $event}},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" "),(item.selectLessons && item.selectLessons.length)?_c('b',[_vm._v(" ("+_vm._s(item.selectLessons.length)+") ")]):_vm._e()]}}])})],1)],1),_c('v-col',[_c('base-panel',{attrs:{"title":"Уроки"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[1]),callback:function ($$v) {_vm.$set(_vm.search, 1, $$v)},expression:"search[1]"}}),_c('base-table',{attrs:{"items":_vm.lessons,"search-text":_vm.search[1],"headers":_vm.positionHeaders,"hide-default-footer":"","notShowSettings":"","notFilter":""},on:{"click:row":_vm.clickLesson},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" "),(item.selectRecords && item.selectRecords.length)?_c('b',[_vm._v(" ("+_vm._s(item.selectRecords.length)+") ")]):_vm._e()]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [(
                  (_vm.selectCourse === null ||
                    (_vm.selectCourse !== null && _vm.lessons.length === 0)) &&
                  !_vm.lessonsLoading
                )?_c('tr',[_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.selectCourse === null ? "Выберите курс!" : "В курсе нет уроков!")+" ")])]):_vm._e()]}}]),model:{value:(_vm.selectLessons),callback:function ($$v) {_vm.selectLessons=$$v},expression:"selectLessons"}})],1)],1),_c('v-col',[_c('base-panel',{attrs:{"title":"Материалы"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[2]),callback:function ($$v) {_vm.$set(_vm.search, 2, $$v)},expression:"search[2]"}}),_c('base-table',{attrs:{"search-text":_vm.search[2],"items":_vm.records,"headers":_vm.employeeHeaders,"hide-default-footer":"","notShowSettings":"","notFilter":""},scopedSlots:_vm._u([{key:"body.append",fn:function(ref){
                var headers = ref.headers;
return [(
                  _vm.currentLesson === null ||
                  (_vm.currentLesson !== null && _vm.records.length === 0)
                )?_c('tr',[_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.currentLesson === null ? "Выберите урок!" : "В уроке нет материалов!")+" ")])]):_vm._e()]}}]),model:{value:(_vm.selectRecords),callback:function ($$v) {_vm.selectRecords=$$v},expression:"selectRecords"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }